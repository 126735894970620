// CAYEMO SKIN

// @font-face {
//   font-family: '12kfont-reg';
//   src: url('./assets/fonts/Kiona-Regular.eot'); /* IE9 Compat Modes */
//   src: url('./assets/fonts/Kiona-Regular.eot?#iefix') format('embedded-opentype'),
//     /* IE6-IE8 */ url('./assets/fonts/Kiona-Regular.otf') format('opentype'),
//     /* Open Type Font */ url('./assets/fonts/Kiona-Regular.svg') format('svg'),
//     /* Legacy iOS */ url('./assets/fonts/Kiona-Regular.ttf') format('truetype'),
//     /* Safari, Android, iOS */ url('./assets/fonts/Kiona-Regular.woff') format('woff'),
//     /* Modern Browsers */ url('./assets/fonts/Kiona-Regular.woff2') format('woff2'); /* Modern Browsers */
//   font-weight: normal;
//   font-style: normal;
// }

@import url('https://fonts.googleapis.com/css?family=Lato:400,900&display=swap');

$font-family-heading: 'Lato', 'Times New Roman', Times, serif;
$font-family-copy: 'Lato', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;

.zwoelfk-font {
  font-family: $font-family-heading;
}

// html {
//   font-size: 16px;
// }

body {
  font-family: $font-family-copy;
  color: #2b2b2b;
}

#root {
  padding-top: $calculated-navbar-height * 1.75;
  @include media-breakpoint-down(sm) {
    padding-top: $calculated-navbar-height-sm * 1.75;
  }
}

#header .main-navigation {
  top: $calculated-navbar-height * 0.75 !important;
  transition: background 0.2s;
  height: $calculated-navbar-height-sm;
  border-bottom: 1px solid $black;
  @include media-breakpoint-up(md) {
    height: $calculated-navbar-height;
  }
  .main-menu {
    transition: all 0.2s;
    display: none;
    .hamburger {
      display: none;
    }
  }
}

.below-main-navigation {
  border-bottom: 1px solid $black;
  height: $calculated-navbar-height * 0.75;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.navbar-text {
  padding-left: $navbar-nav-link-padding-x;
  padding-right: $navbar-nav-link-padding-x;
}

.navbar-dark {
  .nav-link:hover,
  .nav-link:focus,
  .show > .nav-link,
  .active > .nav-link,
  .nav-link.show,
  .nav-link.active {
    // background: rgba(white, 0.1);
    background: transparent !important;
  }
}

.product-listing-page {
  &:not([data-scrolled]) #header .main-navigation.bg-dark {
    background: fade-out($dark, 0.3) !important;
    backdrop-filter: blur(10px) saturate(120%);
    box-shadow: $box-shadow-lg;
  }
  .below-main-navigation {
    //position: absolute;
    //top: $calculated-navbar-height-sm;
    z-index: 1;
    width: 100%;
    @include media-breakpoint-up(md) {
      //top: $calculated-navbar-height;
    }
  }
  #root {
    padding-top: 0;
  }
}

.zwoelfk-mobile-logo {
  max-width: 150px;
}

.product-detail {
  .product-header {
    //margin-top: -$calculated-navbar-height;
    padding-top: 0;
    margin-top: 0;
    position: sticky;
    top: $calculated-navbar-height-sm*1.75;
    @include media-breakpoint-up(md) {
      top: $calculated-navbar-height*1.75;
    }
    max-height: 100vh;

    justify-content: stretch;
    align-items: stretch;
  }

  .product-content {
    position: relative;
    z-index: 1;
    background-color: $body-bg;
  }

  .variant-chooser {
    margin-bottom: $form-group-margin-bottom * 2;
  }
}

.native-slider {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  width: 100%;
  margin-bottom: 0;

  .carousel.carousel-scoll-snap {
    &,
    & .carousel-inner,
    & .carousel-item {
      width: 100%;
      display: flex;
      @supports (align-items: stretch) {
        align-items: stretch;
      }
    }
    & .carousel-item-content {
      width: 100%;
      align-items: top;
      .img-fluid {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-width: auto;
      }
    }
  }
}

h1,
h2,
.btn:not(.btn-link),
legend,
.modal-title,
#footer h5,
.loading-spinner-content {
  font-family: $font-family-heading;
  text-transform: uppercase;
}

// NAV

// .navbar-light {
//   background-color: rgba($color: #fff, $alpha: 0.9) !important;
//   backdrop-filter: blur(10px) saturate(120%);
//   background: linear-gradient(-180deg, rgba($white, 0.9) 0%, rgba($white, 0) 100%);
// }

// TODO better solution?! dont find anyting in variables
// .navbar-light.bg-light {
//   background-color: transparent !important;
// }

#header .navbar .navbar-brand {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0.8rem;
  img {
    height: 1.3rem;
    max-height: 1.3rem;
    margin-left: 1rem;
    // filter: invert(1);
    // margin-top: 0;
    // margin-bottom: 0.35rem;
    // display: none;
    @include media-breakpoint-up(md) {
      display: block;
      height: 1.3rem;
      max-height: 1.3rem;
      margin-right: 0;
      // margin-top: 0.75rem;
      // margin-bottom: 0.75rem;
    }
  }
  &:hover {
    filter: opacity(0.66);
  }
}

.navbar-dark.bg-light {
  background-color: $white !important;
}

.navbar-dark .offcanvas-collapse {
  background: transparent;
}

.offcanvas-collapse {
  top: $calculated-navbar-height-sm + 1rem;
  @include media-breakpoint-up(md) {
    top: $calculated-navbar-height + 1rem;
  }
  //background-color: $gray-900;
  //box-shadow: inset 0 20px 15px -20px rgba(0,0,0,.15);
  .navbar-nav .nav-item {
    margin-bottom: 0.5rem;
    background-color: rgba($color: #2b2b2b, $alpha: 0.9) !important;
    a {
      color: #fff;
    }
    a.active,
    a:hover {
      color: $gray-500;
      font-style: italic;
    }
    font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    box-shadow: -20px 0px 20px 0px rgba(0, 0, 0, 0.5);
  }
}

.offcanvas-collapse {
  //box-shadow: 15px 10px 70px -35px rgba(0,0,0,0.75);
  box-shadow: none;
  background-color: transparent;
}
.offcanvas-collapse-left {
  bottom: auto;
  overflow: visible;
}

.validation-alert {
  @extend .text-danger;

  .validation-alert--message {
    @extend .font-weight-bold;
  }
}

// OC CART

.offcanvas-collapse-right {
  background-color: rgba($color: $primary, $alpha: 0.97) !important;
  box-shadow: -15px 10px 70px -35px rgba(0, 0, 0, 0.75);
  color: #fff;
  a,
  .btn-link {
    color: #fff;
  }
  h5 {
    font-family: $font-family-heading;
    font-size: $h5-font-size;
    text-transform: uppercase;
    //color: $gray-900 !important;
  }
  .btn-primary {
    @extend .btn-info;
  }
}

// MAKI

.maki {
  .card-title {
    font-size: 0.9rem;
    font-family: $font-family-copy;
    .category {
      font-family: $font-family-copy;
    }
    span.sub-title {
      //font-size: 0.8em;
    }
  }
  .maki-img-container {
    overflow: hidden;
  }
  .maki-img {
    backface-visibility: hidden;
  }
  .maki-img-hover {
    transition: opacity 0.3s;
  }
  .price {
    font-size: 0.9rem;
  }
  &:hover,
  &:focus {
    .maki-img {
      opacity: 1;
    }
  }
}

.masonry .sushi-container {
  outline: 1px solid $black !important;
  border-collapse: collapse;

  margin-bottom: 0;
  position: relative;

  min-width: 50%;

  @include media-breakpoint-up(md) {
    min-width: 33.33333%;
  }

  .badge-container {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    .badge {
      font-size: 75%;
      font-weight: bold;
    }
  }

  .card-img-overlay {
    border-top: 1px solid $black;
    position: relative;
    display: flex;
    align-items: flex-end;
  }
}

// PDP

.product-images {
  @include media-breakpoint-up(md) {
    border-right: 1px solid $black;
  }
  img {
    border-bottom: 1px solid $black;
  }
  img:last-child {
    border-bottom: 0;
  }
}

.product-info-wrapper {
  @include media-breakpoint-down(sm) {
    border-top: 1px solid $black;
    z-index: 20;
  }
}

.product-info.sticky-top {
  top: $calculated-navbar-height*3;
  margin-bottom: 4rem;
}

.detail-page {
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  h1 {
    margin-top: 0;
  }
  .variant-chooser-price {
    text-align: left;
    font-weight: normal;
    font-family: $font-family-heading;
    @extend .mb-5;
  }
  .product-title {
    text-align: left !important;
  }
  .product-description {
    text-align: left;
    p {
      text-align: left !important;
    }
  }
}

.carousel-control-prev,
.carousel-control-next {
  color: $primary;
  &:hover,
  &:focus {
    color: lighten($primary, 0.2);
  }
}

.blurry-background-image {
  background-color: transparent;
}

.carousel-indicators {
  display: none;
  position: relative;
  > a,
  > .btn {
    img {
      margin: 0.3rem 0.3rem !important;
      transform: scale(1) !important;
    }
  }
}

.product-thumb {
  box-shadow: none;
  border: none;
}

.radio-list-group .product-thumb {
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 0 0 1px $dark;
  border: 3px solid $white;
  background: $white;
}

// PDP MOBILE

.container.detail-page {
  @include media-breakpoint-down(md) {
    padding-top: 1rem !important;
    h1,
    form {
      text-align: left;
    }
  }
}

// FOOTER

#footer {
  background-color: $white;
  border-top: 1px solid $black;
  color: $primary;
  border-bottom: 1px solid $black;
  img {
    //filter: invert(1);
  }
}

.list-page {
  position: relative;
  background: $body-bg;
  box-shadow: $box-shadow-inverse-lg;
}

.product-listing {
  min-height: 25vh;

  // 3491 = "sale" tag
  .maki .tag-3491 {
    color: transparent;
    background: transparent;
    position: relative;
    margin-bottom: 1.5rem;
    margin-right: 1rem;

    &::after {
      @extend .rounded-circle;
      content: '';
      display: inline-block;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 60px;
      height: 60px;
      background: $white url('./assets/images/sale_tag.svg') no-repeat center center;
    }
  }
}

.video-container {
  .video-controls {
    opacity: 0.5;

    .btn-outline-light {
      border: none;
    }
  }
}

.hero-slider {
  margin-bottom: 0;
}

// CARMENS SPECIALS
// BACKDROP

// .modal-backdrop.show {
//   backdrop-filter: none !important;
// }

// .offcanvas-collapse,
// .offcanvas-collapse-right,
// .radio-list-group,
// .navbar-nav .nav-item {
//   box-shadow: none !important;
// }

// CMS STYLES

.upline {
  font-family: $font-family-heading;
  font-weight: normal;
}

.g8-page  {
  background-color: $primary;
  color: white;
}

$cta-btn-height-sm: 3rem; //$calculated-navbar-height-sm;
$cta-btn-height: 5.5rem; //$calculated-navbar-height;

.landing-page-slider {
  height: 100vh;
  // height: calc(100vh - #{$calculated-navbar-height-sm});
  // @include media-breakpoint-up(md) {
  //   height: calc(100vh - #{$calculated-navbar-height});
  // }
  // height: 85vh;
  // height: calc(100vh - #{$cta-btn-height-sm * 2});
  // @include media-breakpoint-up(md) {
  //   height: 80vh;
  //   height: calc(100vh - #{$cta-btn-height * 2});
  // }
}
.landing-page-ctas {
  > [class*='col-'] {
    display: flex;
    align-items: stretch;
  }
  .btn {
    height: $cta-btn-height-sm;
    @include media-breakpoint-up(md) {
      height: $cta-btn-height;
    }
    display: flex;
    justify-content: center;
    align-items: center;
    transition: font-size 0.05s;

    @include media-breakpoint-up(md) {
      &:hover,
      &:focus {
        font-size: 2em;
      }
    }
  }
}

.hovering-text-container {
  position: relative;
  font-family: $font-family-heading;

  .hovering-text {
    color: $white;
    width: 100%;
    height: 100%;
    padding: 0 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: $h5-font-size;
    @include media-breakpoint-up(md) {
      font-size: 3em;
    }
    @include media-breakpoint-up(lg) {
      font-size: 4em;
    }

    text-align: center;
    text-shadow: 0px 1px 7px rgba(0, 0, 0, 0.5);

    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
}

// Bootstrap introduced min-width: 0; which bugs our layout
.col {
  min-width: unset;
}

.detail-page .alert-info {
  text-align: center;
}

#cookiescript_badge {
  z-index: 999 !important;
}

// safari fix from-to dates on nigiri
@include media-breakpoint-up(lg) {
  .nigiri-date {
    text-align: center;
    flex-direction: column;
  }

  .nigiri-date * + * {
    margin-top: -1rem;
  }
}

// black friday tag 5997

.product-listing {
  min-height: 25vh;

  // 5997 = black friday tag
  .maki .tags:has(.tag-5997) {
    position: absolute;
    top: 50px;
    right: 35px;
  }

  .maki .tag-5997 {
    color: transparent;
    background: transparent;
    position: relative;
    margin-bottom: 1.5rem;
    margin-right: 1rem;

    &::after {
      @extend .rounded-circle;
      content: '';
      display: inline-block;
      position: absolute;
      z-index: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90px;
      height: 90px;
      background: $white url('./assets/images/blackfriday.svg') no-repeat center center;
    }
  }
}

.when-valid,
.is-valid,
.shoppingcart-finisher {
  outline: 1px solid $black !important;
}
